


























import { Vue, Component, Prop } from 'vue-property-decorator';

import Pagination from '@/core/support/pagination/Pagination';
import TableHeaderInterface from '@/shared/lib/interfaces/TableHeaderInterface';
import { DataOptions, DataTableHeader } from 'vuetify';
import { PaginationSortInterface } from '@/core/support/pagination/PaginationInterface';

@Component
export default class Table extends Vue {
  /**
   * Props
   */
  @Prop() private items!: any[];
  @Prop() private headers!: DataTableHeader[];
  @Prop() private pagination!: Pagination;
  @Prop() private classNames!: object;
  @Prop() private emptyInfo!: string;
  @Prop() private itemClass?: any;
  @Prop({ default: 'id' }) private itemKey!: string;

  /**
   * Handle options sync
   * Used for sorting only
   */
  private get optionsSync(): DataOptions {
    const sortBy: string[] = this.sortBy;
    const sortDesc: boolean[] = this.sortDesc;

    return {
      page: this.pagination.getPage(1),
      itemsPerPage: Number(this.pagination.getLimit()),
      sortBy: sortBy.length ? sortBy : ['id'],
      sortDesc: sortDesc.length ? sortBy : [false],
      multiSort: false,
    } as DataOptions;
  }

  private set optionsSync(options: DataOptions) {
    const sort: string[] = options.sortBy.map((sortBy: string, index: number) => {
      return [sortBy, options.sortDesc[index] ? 'desc' : 'asc'].join(',');
    });

    this.$emit('update:sort', sort);
  }

  /**
   * Getters
   */
  private get paginationSort(): PaginationSortInterface[] | null {
    return this.pagination.getSort();
  }

  private get sortBy(): string[] {
    if (!this.paginationSort) {
      return [];
    }

    return this.paginationSort.map((sort: PaginationSortInterface) => {
      return sort.field;
    });
  }

  private get sortDesc(): boolean[] {
    if (!this.paginationSort) {
      return [];
    }

    return this.paginationSort.map((sort: PaginationSortInterface) => {
      return sort.desc === 'desc';
    });
  }

  /**
   * Handlers
   */
  private onRowClick(item: any, index: number) {
    this.$emit('row:click', { item, index });
  }
}
